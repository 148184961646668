import './FooterSection.css';

function FooterSection() {
  return (
    <div className='footer-wrapper'>
        <span className='title'>UW Backgammon</span>
        <span className='copyright'>©2024</span>
    </div>
  );
}

export default FooterSection;